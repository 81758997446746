import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import myimg from './assets/img/slides/braiding.png'
import {Row, Col} from 'react-bootstrap'
import React, {useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Slider from './components/Slider/Slider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './Firebase';
import { Link } from 'react-router-dom';
import 'swiper/css/pagination';
import 'swiper/css';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';
import axios from 'axios'
import { useState, useRef } from 'react';
import { Thumbs } from 'swiper/modules';
import 'swiper/css/navigation';
import bg from './assets/img/bg.jpg'
import { services } from './Model/services';


// Import Swiper styles
import 'swiper/css';

import SpinnerComponents from './components/Spinner/SpinnerComponents'
import NewsSlide from './components/NewsSlide/NewsSlide';
import { useSelector } from 'react-redux';


function App() {
  const myStyle = {
    backgroundImage:
        `url(${bg})`,
    height: "50vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  

  // const [Services, setServices] = useState([])

  const [isloading, setLoading] = useState(false)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);

  const [collections, setCollections] = useState([]);
  // setServices(services)

  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const fetchCollections = async () => {
      const collectionsRef = collection(db, 'Gallery');
      const snapshot = await getDocs(collectionsRef);

      const collectionList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setCollections(collectionList);

      console.log(collections)
    };

    fetchCollections();












  }, []);


const user = useSelector(state => state.firebase)

console.log(user)
  return (
    <>
    <Slider/>
    <Container fluid>

</Container>
    
    
    <div className="App">

      <Container className="bg-white mt-3">
     

      <Row>
        <Col sm={5}>
          
          <img src={myimg} className='about-img mt-4' />
        
        
        
        </Col>
        <Col sm={7}>


<div className="p text-justify py-5 mt-3">

  <h2 className='h3 mt-5 our-heading'> DIEU DONNE HAIR SALON </h2>

<p className='py-1 our-text mt-5 our-desc'>Black-owned beauty salon, located in  Build America Drive In Woodbridge Virginia. We specialize in all kinds of braids, such as Box Braids, Knotless braids, Crochet, Senegalese twist, Faux Locs, Kinky Twist, Marley Twist, Spring Twist, Starter Locs, Passion Twist, Butterfly locks, weave ons and corn rolls, and many more</p>
<Button variant="danger" className='about-btn'><Link to='/about'>Learn More</Link></Button>

</div>


        </Col>
      </Row>
      </Container>


      <Container className="mt-5 mb-5 py-5 home-services">
        <div className='mt-2 mb-2 services-heading'>
        <h2 className='h2 mx-auto text-center'>OUR SERVICES </h2>
        <p className=' mx-auto text-center'>Top Services - </p>
        </div>

      <Swiper

style={{marginTop: 24}}
      spaceBetween={10}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      
      
      breakpoints={{

     
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 2,
        },
        865:{
          slidesPerView:2
        },
        1000:{
          slidesPerView:3
        },
        1500:{
          slidesPerView:3
        },
        1700:{
          slidesPerView:3
        }
    }}
      
      
      
      
      
      >
    

    {services.map(item => (
   
      <SwiperSlide key={item.id}>
  

       <Card style={{ width: '18rem' }}  className='border-0'>
     
      <div className="services-svg">{item.image}

  
      
      </div>
      <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        <Card.Text>
        <p>{item.desc}</p>
        </Card.Text>
        {/* <Button variant="danger" className='services-btn'>Learn More</Button> */}
      </Card.Body>
    </Card>



        </SwiperSlide>

    ))}
  
     
    </Swiper>
<div className='mb-4'>


<Button className='services-btn mb-5  ' variant="danger"><Link to="/services" >More Services</Link></Button>
</div>
      </Container>

   

<Container className="appoinment" style={myStyle} fluid>
  <div className='our-container'>

  <div className="appointment-desc mt-5 py-5">
<h3  className='text-center h4 text-danger our-heading'>WEEKLY SALES - 20% OFF </h3>
  <p className='text-center text-white app-p our-desc'>20% of all braiding, styles, weaves and other selected sevices </p>
<Button variant='danger' className='app-btn'> <Link to="appointment">Book Appointment</Link></Button>
</div>
  </div>


  

</Container>



<Container className="mt-5  mb-4 bg-reviews">

<div className='mt-5 mb-5 services-heading'>
        <h2 className='h2 mx-auto text-center our-heading'>REVIEWS</h2>
        <p className=' mx-auto text-center our-desc'>Google reviews -What Our Customers Are Saying !!!  </p>
        </div>

  <NewsSlide/>
</Container>


      <Container className="mt-5 mx-auto mr-auto py-5 " fluid>
        <div className='mt-5 mb-5 services-heading'>
        <h2 className='h2 mx-auto text-center our-heading'>OUR MOST RECENT SERVICES</h2>
        <p className=' mx-auto text-center our-desc' >Client Looks  - Perfection at its Core </p>
        </div>

      <Swiper

style={{marginTop: 24}}
      spaceBetween={50}
      slidesPerView={5}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      breakpoints={{

     
          0: {
            slidesPerView: 1,
          },
          400:{
            slidesPerView:2,
          },
          639: {
            slidesPerView: 3,
          },
          865:{
            slidesPerView:3
          },
          1000:{
            slidesPerView:4
          },
          1500:{
            slidesPerView:5
          },
          1700:{
            slidesPerView:5
          }
      }}
 
      
      
      
      
      
      >
    

    {collections.map(item => (
   
      <SwiperSlide key={item.id}>
  

       <Card style={{ width: '18rem' }}  className='border-0'>
     
      {/* <div className="services-svg">{item.image}

  
      
      </div> */}
      <img src ={item.src} className='work-images' />
      <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        <Card.Text>
        <p>{item.desc}</p>
        </Card.Text>
        {/* <Button variant="danger" className='services-btn'>Learn More</Button> */}
      </Card.Body>
    </Card>



        </SwiperSlide>

    ))}
  
     
    </Swiper>


      </Container>











    
    </div>

    </>
  );
}

export default App;
