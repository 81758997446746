import React from 'react'
import ImageUpload from '../../components/UploadImages/UploadImages'

const Upload = () => {
  return (
    <div>
      <ImageUpload/>
    </div>
  )
}

export default Upload
