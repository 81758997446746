import React , {useState} from 'react'
import { Container, FloatingLabel, Form,  Button,ListGroup  } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import {Jumbotron} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import about from '../../assets/img/slides/Abouts.png'
import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee'
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone'
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk'
import {faLocation} from '@fortawesome/free-solid-svg-icons/faLocation'



import {getFirestore } from 'firebase/firestore'


import {db} from '../../Firebase'

import emailjs from '@emailjs/browser';

import { collection, addDoc } from "firebase/firestore";






import Mynav from '../../components/Header/Header'

const Contact = () => {

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [tel, setTel] = useState(null);
  const [email, setEmail] = useState('')
  const [desc, setDesc] = useState('')

  const [captchaVerified, setCaptchaVerified] = useState(false);




  const handleSubmit = (e) => {
    e.preventDefault();
 
    addDoc(collection(db, "contact"), {
      name: name,
      tel:tel,
      email:email,
      desc:desc,
     
    }) .then(() => {
      alert('Message sent received , we will get back to you Soon!');
      var templateParams = {
        name: name,
        notes: desc,
        recipient: 'ibrahima703@hotmail.com',
        message: `Message from ${name}, Phone Number is ${tel}, Message :${desc}`
     
      };
      const publickey = 'RG6qCqg7jgAFudkTK'

      emailjs.send('service_euhqfak', 'template_n29j6v3', templateParams,publickey).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          console.log(response.text);
          //alert('Email sent successfully!');
        },
        (error) => {
          console.log(error.text);
          alert('Failed to send message. Please try again later.');
        });
  
      
      setName('');
      setEmail('');
      setTel('');
      setDesc('');
     
    })
    .catch((error) => {
      console.error('Error adding appointment: ', error);
      alert(Error)
    });
  };


  return (
    <Container>
  
     


  <div className='p-5 text-center bg-image'
        // style={{ backgroundImage: "url('https://mdbootstrap.com/img/new/slides/041.webp')", height: 400 }}>
          style={{ backgroundImage: `url(${about})`, height: 400 }}>
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
              <h1 className='mb-3'>Contact</h1>
              <h4 className='mb-3'>Have Questions? Need To Reach Us</h4>
              {/* <a className='btn btn-outline-light btn-lg' href='#!' role='button'>
                Call to action
              </a> */}
            </div>
          </div>
        </div>
        </div>

        <main className="mb-4">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7 mt-4">
                        <p>Want to get in touch? Fill out the form below to send me a message and I will get back to you as soon as possible!</p>
                        <div className="my-5">
                          
                            <form id="contactForm" data-sb-form-api-token="API_TOKEN" onSubmit={handleSubmit}>
                                
                            <>
      <FloatingLabel
        controlId="floatingInput"
        label="Email address"
        className="mb-3"
    
      >
        <Form.Control type="email" 
        placeholder="name@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        
        />
      </FloatingLabel>

      <FloatingLabel controlId="floatingPassword" name="name" label="Enter Name " className="mb-3">
        <Form.Control type="text" placeholder="Name" 
        
        value={name}
        onChange={(e) => setName(e.target.value)}
        
        />
      </FloatingLabel>


      <FloatingLabel
        controlId="floatingInput"
        label="Phone Number"
        className="mb-3"
        
      >
        <Form.Control type="tel" placeholder="Phone Number" 
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        name="tel"
        value={tel}
        onChange={(e) => setTel(e.target.value)}
        
        />
      </FloatingLabel>

     
      <FloatingLabel controlId="floatingTextarea2" label="Comments" className='mb-3 float-right text-right'>
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
          value={desc}
        onChange={(e) => setDesc(e.target.value)}
        />
      </FloatingLabel>

      
    </>
                                <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>
                               
                                <button className="btn btn-danger text-uppercase " id="submitButton" type="submit">Send</button>
                            </form>
                        </div>
                    </div>

                    <div className='col-md-4 col-lg-4 col-xl-5 mt-4'>
                      <div className='py-5'></div>
                      <div className='py-3'></div>
                    <ListGroup as="ul" variant="flush">
      <ListGroup.Item as="li" active>
        <h5 className='h5'><FontAwesomeIcon icon={faPhone}  className='text-danger mr-5 '/> Tel:703-491-5808</h5>
      </ListGroup.Item>
      <ListGroup.Item as="li"><h5 className='h5'><FontAwesomeIcon icon={faMailBulk}  className='text-danger mr-5 ' /> E-mail: ibrahima703@hotmail.com </h5></ListGroup.Item>
      <ListGroup.Item as="li">
      <p className='mr-4 pr-3 h6'><FontAwesomeIcon icon={faLocation}  className='text-danger mr-5'/> 14802 Buildamerica Dr, Woodbridge, VA 22191 </p>
      </ListGroup.Item>
      <ListGroup.Item as="li"></ListGroup.Item>
    </ListGroup>
                    </div>
                </div>
            </div>
        </main>
    </Container>
  )
}

export default Contact
