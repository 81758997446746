
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth"
import { getStorage } from 'firebase/storage';

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAA3W5k9mpggfLWM4_CO6NKoHyaP_5A_fE",
  authDomain: "thesalon-4156e.firebaseapp.com",
  projectId: "thesalon-4156e",
  storageBucket: "thesalon-4156e.appspot.com",
  messagingSenderId: "811841410272",
  appId: "1:811841410272:web:ae02cb490aaeeb2783ec85",
  measurementId: "G-J52JGZ2B4Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcrlQgqAAAAAEq4vaSZ6ANttAt1-QWbiNJdSRuP'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});



  const db  = getFirestore(app)
  const storage = getStorage(app);
  const auth = getAuth(app);
  export {db}
  export {storage}
  export {auth}
// export default app