// src/redux/store.js

import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';

import firebaseReducer from './reducers/firebaseReducer';

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  // Add other reducers as needed
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
