import React, {useState, useEffect} from 'react'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase'
import  Container  from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table';
const Lists = () => {

    const [collections, setCollections] = useState([]);
    const [index, setIndex] = useState(-1);
  
    const [isloading, setLoading] = useState(false)


    useEffect(() => {
        const fetchCollections = async () => {
          const collectionsRef = collection(db, 'appointments');
          const snapshot = await getDocs(collectionsRef);
    
          console.log(snapshot)
          
          const collectionList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    
         
          setCollections(collectionList);
    
          setLoading(true)
         
          console.log(collectionList)
        };
    
        fetchCollections();
    
    
    
    
    
    
    
       
    
    
    
    
      }, []);
  return (
    <Container>
        
        <h2 className='text-center mt-4 mb-4 text-danger'> List of Appointment </h2>
        
        
        <Table responsive>
      <thead>
        <tr>
          <th>#</th>
       

          <th>Date</th>
          <th>Time</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Service</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
       
          
          {collections.map((item, index) => (
            
            <tr key={index}>
               <td>{index}</td>
            <td>{item.date}</td>
            <td>{item.time}</td>
            <td>{item.name}</td>
            <td>{item.tel}</td>
            <td >{item.email}</td>
            <td >{item.services}</td>
            <td>{item.desc}</td>

            </tr>
          ))}
       
        {/* <tr>
          <td>2</td>
          {collections.map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>
        <tr>
          <td>3</td>
          {collections.map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr> */}
      </tbody>
    </Table>
        
        </Container>
  )
}

export default Lists