import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';
import { Container } from 'react-bootstrap';
import Footer from './components/Footer/Footer';
// import Post from './screen/Post/Post';
// import News from './screen/News/News';
import { MemoryRouter, Routes, Route, NavLink, Router, BrowserRouter } from 'react-router-dom';
import Services from './screen/Services/Services';
import Appointment from './screen/Appointment/Appointment'
import Upload from './screen/Upload/Upload';
import Gallery from './screen/Gallery/Gallery';
import About from './screen/About/About';
import Contact from './screen/Contact/Contact';
import store from './Redux/store';
import { Provider, useSelector } from 'react-redux';
import Location from './screen/Location/Location';
import Lists from './screen/Lists/Lists';
import Admin from './components/dashboardLayout/dashboardLayout';
import NotFound from './screen/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(

  <React.StrictMode>
     <Provider store={store}>
    <BrowserRouter>
    
    <Header/>
<Routes>

<Route exact   path="/" Component={App}/>
<Route exact path="/services" Component={Services}/>
<Route exact path="/appointment" Component={Appointment}/>

<Route exact path="/upload" Component={Upload}/>
<Route  exact  path="/gallery" Component={Gallery}/>
<Route  exact  path="/about" Component={About}/>
<Route  exact  path="/contact" Component={Contact}/>
<Route  exact  path="/location" Component={Location}/>
<Route  exact  path="/lists" Component={Lists}/>
<Route  exact  path="/admin" Component={Admin}/>
<Route path='*' Component={NotFound} />
{/* <Route  exact  path="/about" Component={About}/>
<Route  exact path="/shows" Component={Show} />
<Route exact path="/news/:id" Component={Post}/>
<Route exact path="/news" Component={News}/>
<Route exact path="/contact" Component={Contact} />
<Route  exact path="/schedule" Component={Schedule}/>
<Route  exact path="/reviews" Component={Reviews}/>
<Route  exact path="/live" Component={live}/> */}



</Routes>


{/* </Container> */}
<Footer/>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
