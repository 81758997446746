import React from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'

import myimg from '../../assets/hairStyle.png'
import './about.css'
import about from '../../assets/hairStyle.png'
const About = () => {
  return (
    <>
      {/* <Container className='py-2'></Container> */}
    <Container fluid>


    <div className='p-5 text-center bg-image'
        style={{ backgroundImage: `url(${about})`, height: 400 }}>
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white py-3'>
              <h1 className='mb-3'>ABOUT US</h1>
              <p className='mb-3'>Our Story - Mission </p>
              {/* <a className='btn btn-outline-light btn-lg' href='#!' role='button'>
                Call to action
              </a> */}
            </div>
          </div>
        </div>
        </div>

        </Container>

        <Container>

<Row>
        <Col sm={5}>
          
          <img src={myimg} className='about-img mt-4' />
        
        
        
        </Col>
        <Col sm={7}>


<div className="p text-justify py-5 mt-5">

  <h2 className='h3'> DIEU DONNE HAIR SALON </h2>

<p className='py-1 '>Black-owned beauty salon, located in Build America Drive In Woodbridge Virginia. We specialize in all kinds of braids, such as Box Braids, Knotless braids, Crochet, Senegalese twist, Faux Locs, Kinky Twist, Marley Twist, Spring Twist, Starter Locs, Passion Twist, Butterfly locks, weave ons and corn rolls, and many more</p>

</div>


        </Col>
      </Row>


    </Container>
 

   </>
  )
}

export default About
