import React from 'react'
import { Container, Col,Row, ListGroup, Nav } from 'react-bootstrap'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FacebookEmbed } from 'react-social-media-embed';
import { SocialIcon } from 'react-social-icons'
import  'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/youtube'
import { FacebookProvider, Like } from 'react-facebook';
import play from '../../assets/img/slides/playstore.png'

import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee'
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone'
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk'
import {faLocation} from '@fortawesome/free-solid-svg-icons/faLocation'

import app from '../../assets/img/slides/apps.png'
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <Container fluid className="footer-bg py-4 mt-3">
    
    <Row>
      <Col >  
     
    <h5 className='h5 text-center mt-2'>QUICK LINKS</h5>
    <div  className="d-flex justify-content-center align-items-center quick-links">
    <Nav defaultActiveKey="/home"  className="flex-column">

    <Nav.Link to="/about" class="text-white"><Link to="/about">About</Link></Nav.Link>
    <Nav.Link eventKey="link-1" class="text-white"><Link to="/contact">Contact Us</Link></Nav.Link>
    <Nav.Link eventKey="link-2" class="text-white"> <Link to="/privacy">Privacy</Link></Nav.Link>
    <Nav.Link eventKey="link-2" class="text-white"> <Link to="/appointment">Appointment</Link></Nav.Link>
  </Nav>
  </div>
  </Col>
      <Col>
      
    <h5 className='h5 text-center mt-0 ml-0'>VIST US</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <ListGroup  className='mt-2'>
       
       <ListGroup.Item className=""><FontAwesomeIcon icon={faMailBulk} className='bottom-icon text-wrap' />14802 Buildamerica Dr, Woodbridge, VA 22191 </ListGroup.Item>
       <ListGroup.Item className="bottom-list">
       <h5 className='h5'><FontAwesomeIcon icon={faPhone} className='bottom-icon' /><span class="h6"><a href="tel:703-491-5808">703-491-5808</a></span></h5>
       </ListGroup.Item>
       <ListGroup.Item className="quick "><FontAwesomeIcon icon={faMailBulk} className='bottom-icon' /><a href= "mailto: info@dieubeautysalon.com">info@dieubeautysalon.com </a></ListGroup.Item>
     </ListGroup>
  
  </div>
      </Col>



      <Col className='bottom-hours'>
      <h5 className='h5 text-center mt-2 ml-0'>WORKING HOURS</h5>
    <div  className="d-flex justify-content-center align-items-center quick-links">
      <ListGroup  className='mt-1' >
       
       <ListGroup.Item className="text-center">Mon-Sat 7:00am -6:00pm </ListGroup.Item>
       <ListGroup.Item className="text-center">Sun 6:00am - 6:00pm</ListGroup.Item>
       {/* <ListGroup.Item className="text-center">Tel: 703-491-5808</ListGroup.Item> */}

       <div className="d-flex mt-2 mx-auto text-center mr-auto justify-content-center">
    <SocialIcon url="https://www.facebook.com/Dievdonne/" />
    <SocialIcon url="www.instagram.com" />
    <SocialIcon url="www.youtube.com" />
   
    </div>
       
     </ListGroup>
      </div>
      </Col>
      <Col>
      <h5 className='h5 text-center mt-2'>FACEBOOK </h5>
      
   
      <div style={{ display: 'flex', justifyContent: 'center' }}>
  {/* <FacebookEmbed url="https://www.facebook.com/Dievdonne/" width={250} /> */}




  <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDievdonne%2F&tabs=timeline&width=400&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="250" height="200"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>


  


</div>
    
 

<hr/>
    <div className='d-flex mr-1 mx-auto'>
    {/* <img src={app} className='appimage' />
    <img src={play} className='appimage' /> */}
    </div>
   
    
      
      
      </Col>
    </Row>



    </Container>
    <footer className='bg-dark footer-container' fluid>        
        <Row>
        <Col sm={6} className='mt-3 mx-auto text-white'><p>2024&copy;dieudonnebraids&style</p></Col>
        <Col sm={6} className='mt-3 mx-auto text-white bottom-dec'><p>Developed By Kweku Anaisie </p></Col>
      </Row>
        
        </footer>

        </>
  )
}

export default Footer